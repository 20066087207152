@import '../layout/_vars';

$loader-color: $tote-secondary;

.container {
    min-width: 100%;
    min-height: calc(100vh - 50px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.loader {
    display: inline-block;
    width: 80px;
    height: 80px;

    &:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid $loader-color;
        border-color: $loader-color transparent $loader-color transparent;
        animation: loaderDualRing 1.2s linear infinite;
    }

    + p {
        margin-top: 1rem;
    }
}

@keyframes loaderDualRing {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
  