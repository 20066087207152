@import "../layout/_vars";

.calendarWrapper {
    height: 100%;
    max-height: 100vh;
    display: flex;
    flex-flow: column nowrap;

    :global {
        @import "~react-big-calendar/lib/sass/styles.scss";

        .rbc-calendar {
            max-width: 100%;
            height: 100%;
            overflow-x: hidden;
        }

        .rbc-event,
        .rbc-day-slot .rbc-background-event {
            background-color: $tote-secondary;
        }

        .rbc-month-view .rbc-event {
            height: 47px !important;
        }

        .rbc-time-view .rbc-day-slot .rbc-event-label,
        .rbc-time-view .rbc-day-slot .rbc-event-content {
            line-height: 21px;
        }

        .rbc-today {
            background-color: lighten(gray, 20%);
        }

        @media (max-width: 990px) {
            .rbc-toolbar {
                flex-direction: column;
            }
        }
    }

    :global(.rbc-month-view) .calendarEventContent small {
        display: inline;
    }

    :global(.rbc-event-label + .rbc-event-content)>.calendarEventContent {
        display: block;
        overflow: hidden;

        .calendarEventTime {
            display: none;
        }

        small {
            padding-left: 5px;
        }
    }

    .calendarEventTypeInstore,
    :global(.rbc-day-slot) .calendarEventTypeInstore {
        border-left: 9px solid $in-person-event;
    }

    .calendarEventTypeVirtual,
    :global(.rbc-day-slot) .calendarEventTypeVirtual {
        border-left: 9px solid $virtual-event;
    }

    .calendarEventTypeEmployee,
    :global(.rbc-day-slot) .calendarEventTypeEmployee {
        border-left: 9px solid $employee-event;
    }
}

.calendarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 10px;

    .actions {
        @media only screen and (max-width: 1326px) {
            & {
                font-size: 0.7rem;
                padding: 3.5px 9px;
            }
        }
    }

    .locationsSelect {
        min-width: 0;
        max-width: 400px;

        @media only screen and (max-width: 1326px) {
            & {
                max-width: 275px;
            }
        }
    }

    .calendarLegend {
        display: flex;
        align-items: center;
        font-size: 0.9rem;

        @mixin legendDot {
            display: block;
            margin: 0 9px;

            &::before {
                content: "";
                display: inline-block;
                width: 9px;
                height: 18px;
                border-radius: 10px;
                margin-right: 5px;
                vertical-align: middle;
            }
        }

        .calendarLegendVirtual {
            @include legendDot;

            &::before {
                background-color: $virtual-event;
            }
        }

        .calendarLegendInstore {
            @include legendDot;

            &::before {
                background-color: $in-person-event;
            }
        }

        .calendarLegendEmployee {
            @include legendDot;

            &::before {
                background-color: $employee-event;
            }
        }
    }

    @media (max-width: 990px) {
        flex-direction: column;

        .exportWrapper,
        .calendarLegend {
            display: none;
        }

        .actions {
            font-size: 0.85rem;
        }
    }
}

.calendarEventContent {
    display: flex;
    flex-flow: column wrap;
    height: 100%;
    color: $tote-secondary-text;

    div {
        line-height: inherit !important;
        height: 100%;
    }

    small {
        display: none;
    }

    .declined {
        text-decoration: line-through;
    }
}

.selectedApptContainer {
    border-radius: 5px;
    z-index: 5;
    max-width: 750px;
    box-shadow: 0 0 20px rgba(#000, 0.15);
    position: absolute;
    top: 0;

    .selectedApptArrow {
        display: none;
    }

    &.selectedApptLoaded {
        background: #fff;
        border: 1px solid #ccc;

        .selectedApptArrow {
            display: block;
        }
    }

    &[data-popper-reference-hidden] {
        visibility: hidden;
        pointer-events: none;
    }

    &[data-popper-placement^="top"]>.selectedApptArrow {
        bottom: -6px;

        &::before {
            border-bottom-width: 1px;
            border-right-width: 1px;
        }
    }

    &[data-popper-placement^="bottom"]>.selectedApptArrow {
        top: -6px;

        &::before {
            border-top-width: 1px;
            border-left-width: 1px;
        }
    }

    &[data-popper-placement^="left"]>.selectedApptArrow {
        right: -6px;

        &::before {
            border-top-width: 1px;
            border-right-width: 1px;
        }
    }

    &[data-popper-placement^="right"]>.selectedApptArrow {
        left: -6px;

        &::before {
            border-left-width: 1px;
            border-bottom-width: 1px;
        }
    }

    section {
        margin-top: 20px;
    }

    h2 {
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 0;
    }

    h3 {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }

    :global(.form-group) {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    :global(.form-group > .form-row) {
        width: 100%;
        margin-left: -10px;
    }

    label {
        display: inline-block;
        width: 150px;
        margin-bottom: 0;
        font-size: 0.9rem;
    }
}

.selectedApptArrow {

    &,
    &::before {
        position: absolute;
        width: 12px;
        height: 12px;
        z-index: -1;
    }

    &::before {
        content: "";
        transform: rotate(45deg);
        background: #fff;
        border-color: #ccc;
        border-width: 0;
        border-style: solid;
    }
}

.appointment {
    overflow-y: auto;
    max-height: 90vh;
    padding: 20px;

    @media (max-width: 430px) {
        max-width: 300px;
    }
}

.apptFormHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h3.apptFormHeader__Heading {
        margin-bottom: 0;
    }

    .statusSelect {
        width: auto;
    }
}

.apptFormClose {
    width: 36px;
    height: 36px;
    padding: 5px;
    background: none;
    border: 0;
    border-radius: 50%;
    text-align: center;
    transition: background-color 0.2s;

    :global(svg) {
        stroke: #000;
        width: 12px;
        vertical-align: middle;
    }

    &:hover {
        background: #ccc;
    }
}

.apptQuestionnaire {

    p,
    ol {
        font-size: 0.95rem;
    }
}

.dateTimeWrapper {
    @media (max-width: 430px) {
        flex-direction: column;

        .dateTimeCol {
            max-width: none;
        }
    }

}

.syncButton {
    background: #281A1A;
    border: none;
    color: #FDF5E9;
    border-radius: 3px;
}

.appointmentInfoContainer {
    display: flex;
    flex-wrap: wrap;

    .column {
        flex: 1;
        padding: 10px;
        margin: 10px;
        border: 1px solid #ccc;
        box-shadow: 2px 2px 8px #aaa;
    }

    h2 {
        font-size: 18px;
        color: #333;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    table,
    th,
    td {
        border: 1px solid #ddd;
        padding: 8px;
    }

    th {
        background-color: #f4f4f4;
    }
}